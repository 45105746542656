import React from "react";
import { isEmpty } from "radash";
import { JwtUtils } from "#utils";
import { Routes, Constants } from "#core";
import { useTranslation } from "react-i18next";
import { IUser, QueryHooks, MutationHooks } from "@nubeteck/queries";

interface IAuthContextProps {
  isLogged: boolean;
  user: null | IUser;
  logout: () => void;
  addOneAttempt: () => void;
  verifyTokenExpiration: () => Promise<boolean>;
}

type AuthReducerAction =
  | { type: "CLEAR_AUTH" }
  | { type: "ADD_USER_DATA"; data: IUser }
  | { type: "SET_ATTEMPT_COUNT"; data: number };

type AuthReducerState = {
  user: null | IUser;
  attemptCount: number;
};

const reducer = (
  state: AuthReducerState,
  action: AuthReducerAction,
): AuthReducerState => {
  if (action.type === "ADD_USER_DATA") return { ...state, user: action.data };
  if (action.type === "SET_ATTEMPT_COUNT")
    return { ...state, attemptCount: action.data };
  if (action.type === "CLEAR_AUTH") return { user: null, attemptCount: 0 };

  return state;
};

const AuthContext = React.createContext<IAuthContextProps>({
  user: null,
  isLogged: false,
  logout: () => null,
  addOneAttempt: () => null,
  verifyTokenExpiration: async () => false,
});

// eslint-disable-next-line react-refresh/only-export-components
export const useAuth = () => React.useContext(AuthContext);

interface IProviderProps {
  children?: React.ReactNode;
  renderLoading: (message: string) => React.JSX.Element;
}

const Provider = ({ children, renderLoading }: IProviderProps) => {
  const { t } = useTranslation("app");

  const [state, dispatch] = React.useReducer(reducer, {
    user: null,
    attemptCount: 0,
  });

  const userMe = QueryHooks.useFetchUserMe();
  const authRefreshToken = MutationHooks.useAuthRefreshToken();

  React.useEffect(() => {
    if (userMe.data) {
      dispatch({ data: userMe.data, type: "ADD_USER_DATA" });
    }
  }, [userMe.data, userMe.isSuccess]);

  const verifyTokenExpiration = React.useCallback(async () => {
    const token = localStorage.getItem(Constants.TOKEN_KEY_NAME) ?? "";
    if (!token) return false;

    const decodedToken = JwtUtils.decodeJwt(token);
    const expTime = decodedToken.exp;
    const todayDate = Math.floor(new Date().getTime() / 1000.0);

    if (expTime - todayDate < 60) {
      const refreshToken = localStorage.getItem(
        Constants.REFRESH_TOKEN_KEY_NAME,
      );
      if (!refreshToken) return false;

      const newToken = await authRefreshToken.mutateAsync(refreshToken);

      if (isEmpty(newToken) || !newToken) return false;

      localStorage.setItem("token", token);
    }

    return true;
  }, [authRefreshToken]);

  const logout = React.useCallback(async () => {
    dispatch({ type: "CLEAR_AUTH" });
    localStorage.removeItem(Constants.TOKEN_KEY_NAME);
    localStorage.removeItem(Constants.REFRESH_TOKEN_KEY_NAME);
    window.location.href = Routes.BASE_ROUTE;
  }, []);

  const addOneAttempt = React.useCallback(() => {
    dispatch({ type: "SET_ATTEMPT_COUNT", data: state.attemptCount + 1 });
  }, [state.attemptCount]);

  return (
    <AuthContext.Provider
      value={{
        logout,
        addOneAttempt,
        user: state.user,
        verifyTokenExpiration,
        isLogged: !!Object.keys(state?.user ?? {}).length,
      }}
    >
      {userMe.isPending && !userMe.data
        ? renderLoading(t("loading_user_data_message"))
        : children}
    </AuthContext.Provider>
  );
};

export default Provider;
