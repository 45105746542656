import React from "react";
import { Result } from "antd";
import { Routes } from "#core";
import { Icon } from "@nubeteck/icons";
import {
  Flex,
  Button,
  RequiredSVG,
  useBreadcrumbActions,
} from "@nubeteck/components";

const Unauthorized = () => {
  const { dispatch } = useBreadcrumbActions();

  React.useEffect(() => {
    dispatch({ payload: true, type: "SET_DISABLED" });
    return () => dispatch({ payload: false, type: "SET_DISABLED" });
  }, [dispatch]);

  return (
    <Result
      title="Sin autorización"
      icon={<RequiredSVG width="100%" height="40vh" />}
      subTitle="No posee la autorización requerida para visualizar esta pagina."
      extra={
        <Flex style={{ alignItems: "center", justifyContent: "center" }}>
          <Button
            size="large"
            type="primary"
            href={Routes.BASE_ROUTE}
            renderLeftIcon={<Icon size={20} name="home" />}
          >
            Volver a la pantalla principal
          </Button>
        </Flex>
      }
    />
  );
};

export default Unauthorized;
