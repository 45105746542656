import { Heading, IBoxProps } from "@nubeteck/components";

import { OverlayStyled, LoadingImgStyled } from "./loading-animate.styled";

interface ILoadingAnimateProps extends IBoxProps {
  imgAlt: string;
  imgSrc: string;
  message: string;
  imgHeight?: number;
}

const LoadingAnimate = ({
  imgSrc,
  imgAlt,
  imgHeight = 120,
  message = "Loading...",
}: ILoadingAnimateProps) => {
  return (
    <OverlayStyled>
      <LoadingImgStyled src={imgSrc} alt={imgAlt} height={imgHeight} />
      <Heading level={5} style={{ fontFamily: "Poppins" }}>
        {message}
      </Heading>
    </OverlayStyled>
  );
};

export default LoadingAnimate;
