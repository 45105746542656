import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

import { Locales } from "./core";

export const resources = {
  en: {
    key: "English (en)",
    app: Locales.LocaleEN,
    error_app: Locales.LocaleError.en,
  },
  es: {
    key: "Español (es)",
    app: Locales.LocaleES,
    error_app: Locales.LocaleError.es,
  },
} as const;

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    resources,
    defaultNS: "app",
    fallbackLng: "es",
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
