import styled from "styled-components";
import { Card } from "@nubeteck/components";

export const EditorCard = styled(Card)`
  .ProseMirror {
    height: 200px;
    overflow-y: auto;
    padding: 0px 10px;
  }

  .tiptap {
    blockquote {
      border-left: 3px solid rgba(13, 13, 13, 0.1) !important;
      padding-left: 1rem !important;
    }

    hr {
      border: none;
      border-top: 2px solid rgba(13, 13, 13, 0.1);
      margin: 1rem 0;
    }
  }

  .ProseMirror:focus-visible {
    outline: auto !important;
    outline-color: #29aae242 !important;
    outline-width: 0.4px !important;
  }
`;
