import { BillingModule } from "@nubeteck/billing_module";
import { ContactsModule } from "@nubeteck/contacts_module";
import { InsuranceModule } from "@nubeteck/insurance_module";
import { ReportingModule } from "@nubeteck/reporting_module";
import { InventoryModule } from "@nubeteck/inventory_module";
import { FinancingModule } from "@nubeteck/financing_module";
import { AccountingModule } from "@nubeteck/accounting_module";
import { ProductionModule } from "@nubeteck/production_module";
import { CommissionsModule } from "@nubeteck/commissions_module";
import ReconciliationModule from "@nubeteck/reconciliation_module";
import { AccountsPayableModule } from "@nubeteck/accounts_payable_module";
import { AccountsReceivableModule } from "@nubeteck/accounts_receivable_module";

import i18n from "./I18next.config";
import container from "./inversify.container";

ContactsModule.register(container, i18n);
FinancingModule.register(container, i18n);
InventoryModule.register(container, i18n, true);
CommissionsModule.register(container, i18n, false, false);
InsuranceModule.register(container, i18n, false, false);
AccountingModule.register(container, i18n, false, true);
ProductionModule.register(container, i18n);
AccountsReceivableModule.register(container, i18n, false, false);
AccountsPayableModule.register(container, i18n);
BillingModule.register(container, i18n);
ReconciliationModule.register(container, i18n);
ReportingModule.register(container, i18n);

export default container;
