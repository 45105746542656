import { DefaultTheme } from "styled-components";

import { themeColors } from "./colors";

export default {
  config: {},
  mode: "light",
  colors: themeColors,
  fontFamily: "Poppins",
  locale: {
    emptyText: "No hay datos",
    errorText: "Hubo un error",
  },
} as DefaultTheme;
