import styled, { keyframes } from "styled-components";
import { Box, Image, IBoxProps } from "@nubeteck/components";

interface IAnimateImageProps extends IBoxProps {
  src: string;
  alt?: string;
  height?: number;
}

const ImgAnimation = keyframes`
  0% {
    filter: drop-shadow(0 0 0rem #29aae222)
  }
  30% {
    transform:scale(1.05);
  }
  100% {
    filter: drop-shadow(0 0 10rem #29aae222);
    transform:scale(1);
  }
`;

const AnimatedImageStyled = styled(Image)`
  animation-name: ${ImgAnimation};
  animation-duration: 2s;
  animation-iteration-count: infinite;
`;

const AnimateImage = ({ src, height, ...props }: IAnimateImageProps) => {
  return (
    <Box {...props}>
      <AnimatedImageStyled src={src} height={height} preview={false} />
    </Box>
  );
};

export default AnimateImage;
