export const themeColors = {
  primary100: "#E9F9D6",
  primary200: "#CFF4AE",
  primary300: "#A5E080",
  primary400: "#7BC25A",
  primary500: "#479A2C",
  primary600: "#318420",
  primary700: "#1F6E16",
  primary800: "#10590E",
  primary900: "#08490A",
};

export type ThemeColors = keyof typeof themeColors;
