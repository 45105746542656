export const decodeJwt = (token: string) => {
  if (token === "" || !token) return;

  try {
    return JSON.parse(
      decodeURIComponent(
        window
          .atob(token.split(".")[1].replace("-", "+").replace("_", "/"))
          .split("")
          .map((c) => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
          .join(""),
      ),
    );
  } catch (error) {
    console.error(error);
  }
};
