import styled, { keyframes } from "styled-components";

import { AnimateImage } from "../animate-image";

const ImgGradientAnimation = keyframes`
  0% {
   background-position:100% 100%;
  }
  100% {
    background-position:0% 50%;
  }
`;

export const OverlayStyled = styled.div`
  opacity: ${({ hidden = false }) => (hidden ? 0 : 1)};
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  z-index: 20;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #ffffffca;
  backdrop-filter: blur(5px);
`;

export const LoadingImgStyled = styled(AnimateImage)`
  &::after {
    display: block;
    position: absolute;
    top: ${({ height }) => `calc(50% - ${height}px / 2)`};
    description: "";
    width: 230px;
    height: ${({ height }) => `${height}px`};
    animation-duration: 2s;
    animation-iteration-count: infinite;
    animation-name: ${ImgGradientAnimation};
    background: linear-gradient(
      105deg,
      transparent 40%,
      #ffffff50 50%,
      transparent 60%
    );
    background-size: 300% 300%;
  }
`;
