import { Icon } from "@nubeteck/icons";
import { Tooltip, IIconProps } from "@nubeteck/components";

export interface ITooltipIconProps extends IIconProps {
  title: string;
}

const TooltipIcon = ({ title, ...props }: ITooltipIconProps) => {
  return (
    <Tooltip title={title}>
      <Icon {...props} />
    </Tooltip>
  );
};

export default TooltipIcon;
