import React from "react";
import { objectify } from "radash";
import { notification } from "antd";
import { Unauthorized } from "#components";
import { QueryHooks } from "@nubeteck/queries";
import { useTranslation } from "react-i18next";
import { AbacContextProvider } from "@nubeteck/abac";

interface IProviderProps {
  children?: React.ReactNode;
  renderLoading: (message: string) => React.JSX.Element;
}

const Provider = ({ children, renderLoading }: IProviderProps) => {
  const { t } = useTranslation("app");

  const [notify, contextHolder] = notification.useNotification();
  const [permissions, setPermissions] = React.useState<Record<string, boolean>>(
    {},
  );

  const usersPermissions = QueryHooks.useFetchUsersPermissions(0);

  React.useEffect(() => {
    setPermissions(
      objectify(
        usersPermissions.data?.[0]?.Permisos?.split(",") ?? [],
        (perm) => perm,
        () => true,
      ),
    );
  }, [usersPermissions.data]);

  React.useEffect(() => {
    if (usersPermissions.isError && usersPermissions.isFetched) {
      notify.error({
        duration: 0,
        message: "Hubo un error al conectar con el servidor.",
        description:
          "Comprueba tu conexión y reinicia la pagina, si el error continua contacta al administrador.",
      });
    }

    return () => notify.destroy();
  }, [notify, usersPermissions.isError, usersPermissions.isFetched]);

  const isLoadingRendered = React.useMemo(() => {
    return usersPermissions.isPending && !permissions;
  }, [permissions, usersPermissions.isPending]);

  return (
    <AbacContextProvider
      permissions={permissions}
      noAccessComponent={Unauthorized}
    >
      {isLoadingRendered
        ? renderLoading(t("loading_permissions_message"))
        : children}
      {contextHolder}
    </AbacContextProvider>
  );
};

export default Provider;
