import { Container } from "inversify";

const container = new Container({ defaultScope: "Singleton" });

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type Newable<T> = new (...args: any[]) => T;
export interface Abstract<T> {
  prototype: T;
}
export type ServiceIdentifier<T = unknown> =
  | string
  | symbol
  | Newable<T>
  | Abstract<T>;

// monkey patching `container.getAll`
const getAll = container.getAll.bind(container);
container.getAll = function <T>(serviceIdentifier: ServiceIdentifier<T>): T[] {
  try {
    return getAll(serviceIdentifier);
  } catch (err) {
    return [];
  }
};

export default container;
