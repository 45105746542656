export const BASE_ROUTE = "/";
export const LOGIN_ROUTE = "/login";
export const REDIRECTING_ROUTE = "/redirecting";
export const FORGOT_PASSWORD_ROUTE = "/forgot-password";

// Account settings routes
export const ACCOUNT_SETTINGS_ROUTE = "/account-settings";
export const ACCOUNT_SETTINGS_PASSWORD_ROUTE = "/password";
export const ACCOUNT_SETTINGS_INTEGRATIONS_ROUTE = "/integrations";
export const ACCOUNT_SETTINGS_NOTIFICATIONS_ROUTE = "/notifications";

// Settings routes
export const SETTINGS_ROUTE = "/settings";
export const SETTINGS_USERS_ROUTE = "/users";
export const SETTINGS_TAXES_ROUTE = "/taxes";
export const SETTINGS_TEMPLATES_ROUTE = "/templates";
export const SETTINGS_CURRENCIES_ROUTE = "/currencies";
export const SETTINGS_PERMISSIONS_ROUTE = "/permissions";
export const SETTINGS_TEMPLATES_TYPES_ROUTE = "/types-templates";

export const SETTINGS_BANKS_ROUTE = "/banks";
export const SETTINGS_PRINTERS_ROUTE = "/printers";
